import React from "react";

export const Logo = ({name, appointment}) => {

  return (
    <div className="w-40">
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 821.09 312.57">
      <path fill="white" className="cls-1" d="M511.4,281.89a3.56,3.56,0,0,1-3.53,3.59H497.74v34a3.56,3.56,0,1,1-7.12,0v-34H480.55a3.57,3.57,0,0,1,0-7.13h27.32A3.54,3.54,0,0,1,511.4,281.89Z" transform="translate(-10.4 -10.6)"/>
      <path fill="white" className="bg-white" d="M556.39,322.75a3.51,3.51,0,0,1-4.71-1.53L547.09,312h-20.2a1,1,0,0,1-.41,0l-4.24,9.12a3.53,3.53,0,0,1-3.19,2.06,3.15,3.15,0,0,1-1.47-.35,3.48,3.48,0,0,1-1.7-4.65L533.07,281a3.6,3.6,0,0,1,3.12-2.06h.06a3.64,3.64,0,0,1,3.18,1.94L558,318A3.45,3.45,0,0,1,556.39,322.75ZM543.55,305l-7.18-14.37L529.71,305Z" transform="translate(-10.4 -10.6)"/>
      <path fill="white" className="cls-1" d="M604.2,301.73c0,12.25-9.13,21.14-21.73,21.14-12.25,0-21.79-9.84-21.79-22.38a22.38,22.38,0,0,1,37.69-16.25,3.61,3.61,0,0,1,.11,5.07,3.53,3.53,0,0,1-5,.17,15,15,0,0,0-10.42-4.18,15.2,15.2,0,0,0-15.2,15.19c0,8.54,6.42,15.25,14.61,15.25,7.6,0,13.19-4.59,14.37-11.36h-7.72a3.56,3.56,0,1,1,0-7.12h11.43a3.55,3.55,0,0,1,3.59,3.53v.29A2.18,2.18,0,0,1,604.2,301.73Z" transform="translate(-10.4 -10.6)"/>
      <path fill="white" className="cls-1" d="M654.59,300l-2.7,2.36a11.83,11.83,0,0,1,2.06,8.3,13.69,13.69,0,0,1-11.19,12.13,13.31,13.31,0,0,1-3.06.35,14.63,14.63,0,0,1-9.6-3.71,15.41,15.41,0,0,1-5.24-12.71,14.2,14.2,0,0,1,5.95-10.66,8,8,0,0,1-1.12-1.71A10.68,10.68,0,0,1,634.34,280a10.52,10.52,0,0,1,8.12-.65,10.64,10.64,0,0,1,6.19,5.3,3.25,3.25,0,0,1-1.42,4.36,3.14,3.14,0,0,1-4.29-1.41,4.2,4.2,0,1,0-7.48,3.83,5.3,5.3,0,0,0,3,2.23h.06a3.24,3.24,0,0,1-.3,6.36,7.41,7.41,0,0,0-6.88,7.24,8.74,8.74,0,0,0,3,7.31,8,8,0,0,0,7,1.94c4.07-.88,5.48-3.42,5.95-5.36a6.52,6.52,0,0,0-.29-4.47l-4.06,3.53a2.93,2.93,0,0,1-2.12.82,3.11,3.11,0,0,1-2.42-1.12,3.18,3.18,0,0,1,.3-4.53l11.66-10.25a3.23,3.23,0,0,1,4.23,4.89Z" transform="translate(-10.4 -10.6)"/>
      <path fill="white" className="cls-1" d="M710.7,281.89a3.55,3.55,0,0,1-3.53,3.59H697v34a3.56,3.56,0,1,1-7.12,0v-34H679.85a3.57,3.57,0,0,1,0-7.13h27.32A3.54,3.54,0,0,1,710.7,281.89Z" transform="translate(-10.4 -10.6)"/>
      <path fill="white" className="cls-1" d="M720.12,322.87a3.56,3.56,0,0,1-3.53-3.59V281.77a3.57,3.57,0,1,1,7.13,0v37.51A3.58,3.58,0,0,1,720.12,322.87Z" transform="translate(-10.4 -10.6)"/>
      <path fill="white" className="cls-1" d="M764,281.89a3.56,3.56,0,0,1-3.53,3.59H750.33v34a3.57,3.57,0,1,1-7.13,0v-34H733.13a3.57,3.57,0,0,1,0-7.13h27.33A3.54,3.54,0,0,1,764,281.89Z" transform="translate(-10.4 -10.6)"/>
      <path fill="white" className="cls-1" d="M771.58,323.1a3.42,3.42,0,0,1-2.41-1,3.5,3.5,0,0,1-1.06-2.47V281.89a3.48,3.48,0,0,1,3.47-3.48,3.44,3.44,0,0,1,3.48,3.48V316.1l17.31-.18h.06a3.48,3.48,0,0,1,0,6.95l-20.79.23Z" transform="translate(-10.4 -10.6)"/>
      <path fill="white" className="cls-1" d="M807,304.26v11.66h16.84a3.54,3.54,0,0,1,3.53,3.53,3.49,3.49,0,0,1-3.53,3.48H803.49a3.44,3.44,0,0,1-3.47-3.48V281.77a3.49,3.49,0,0,1,3.47-3.53h20.38a3.53,3.53,0,0,1,0,7.06H807v12h16.84a3.5,3.5,0,1,1,0,7Z" transform="translate(-10.4 -10.6)"/>
      <path fill="#eb1e74" className="cls-2" d="M230.5,157.67v84.1a12.56,12.56,0,0,1-12.73,12.52c-7.25,0-11.64-5.71-11.64-12.52v-2.63c-9.44,8.12-20.42,13-33.6,13a53,53,0,1,1,0-106.06c13.18,0,24.16,5.05,33.6,12.74v-1.1c0-6.81,4.39-12.52,11.64-12.52A12.56,12.56,0,0,1,230.5,157.67ZM200,199.17a27.56,27.56,0,1,0-27.45,27.23A27.6,27.6,0,0,0,200,199.17Z" transform="translate(-10.4 -10.6)"/>
      <path fill="white" className="cls-1" d="M112.5,171.91a13,13,0,0,1-13.17,13H36.53v56.65a13,13,0,0,1-13.18,12.95A12.84,12.84,0,0,1,10.4,241.52V101a13,13,0,0,1,13-13.18h76a13.18,13.18,0,0,1,0,26.35H36.53v44.58h62.8A13.06,13.06,0,0,1,112.5,171.91Z" transform="translate(-10.4 -10.6)"/>
      <path fill="white" className="cls-1" d="M285.75,256.23c-14.06,0-28.11-5.71-35.36-12.52a13.13,13.13,0,0,1,17.79-19.32c3.95,3.73,18.66,8.78,27,3.29a7.27,7.27,0,0,0,3.51-6.14c0-3.08-2.63-5.27-4.83-6.15-2-1.1-5.49-1.1-9.44-1.1-5.27,0-11.2-.22-17.35-2.41-11.42-3.74-20.64-15.6-21.52-27-1.1-13.84,4.83-22.4,9.88-27.23,11-10.1,27-11.64,38.65-9.66,8.78,1.53,15.37,4.61,20.86,9.66a13.13,13.13,0,0,1,.88,18.44,13.42,13.42,0,0,1-11.2,4.18,13.64,13.64,0,0,1-5.71-2c-3.07-2.2-5.27-3.73-9.22-4.39-6.59-1.1-18.89-.66-18,8.78.22,1.1,2.2,4,3.95,4.61,2.2.66,5.49.66,9,.66,5.71.22,13,.22,20.2,3.51,12.08,5.49,20,17.35,20,30.09a34,34,0,0,1-15.15,28.1C302.21,254.47,294.09,256.23,285.75,256.23Z" transform="translate(-10.4 -10.6)"/>
      <path fill="white" className="cls-1" d="M536.94,139.19c0,25.91-22.84,48.09-50.07,48.09H452.18V242.4a13,13,0,0,1-25.91,0V103c0-6.8,3.51-14.27,12.29-14.27h50.07C514.76,88.69,536.94,111.74,536.94,139.19Zm-26.13,0c0-12.73-10.54-24.37-22.18-24.37H452.18v46.33h34.69C499.61,161.15,510.81,150.83,510.81,139.19Z" transform="translate(-10.4 -10.6)"/>
      <path fill="#96c93f" className="cls-3" d="M646.94,158.52v84.1a12.56,12.56,0,0,1-12.73,12.51c-7.25,0-11.64-5.71-11.64-12.51V240c-9.44,8.13-20.42,13-33.6,13a53,53,0,1,1,0-106.06c13.18,0,24.16,5.05,33.6,12.73v-1.09c0-6.81,4.39-12.52,11.64-12.52A12.56,12.56,0,0,1,646.94,158.52ZM616.42,200A27.56,27.56,0,1,0,589,227.25,27.61,27.61,0,0,0,616.42,200Z" transform="translate(-10.4 -10.6)"/>
      <path fill="white" className="cls-1" d="M704.14,256.23c-14,0-28.11-5.71-35.35-12.52a13.13,13.13,0,0,1,17.78-19.32c3.95,3.73,18.67,8.78,27,3.29a7.25,7.25,0,0,0,3.51-6.14c0-3.08-2.63-5.27-4.83-6.15-2-1.1-5.49-1.1-9.44-1.1-5.27,0-11.2-.22-17.35-2.41-11.41-3.74-20.64-15.6-21.52-27-1.09-13.84,4.84-22.4,9.89-27.23,11-10.1,27-11.64,38.64-9.66,8.79,1.53,15.37,4.61,20.86,9.66a13.13,13.13,0,0,1,.88,18.44,13.41,13.41,0,0,1-11.2,4.18,13.69,13.69,0,0,1-5.71-2c-3.07-2.2-5.27-3.73-9.22-4.39-6.59-1.1-18.88-.66-18,8.78.22,1.1,2.19,4,3.95,4.61,2.19.66,5.49.66,9,.66,5.71.22,13,.22,20.2,3.51,12.08,5.49,20,17.35,20,30.09a34,34,0,0,1-15.15,28.1C720.61,254.47,712.48,256.23,704.14,256.23Z" transform="translate(-10.4 -10.6)"/>
      <path fill="white" className="cls-1" d="M792.41,256.23c-14.06,0-28.11-5.71-35.36-12.52a13.13,13.13,0,0,1,17.79-19.32c3.95,3.73,18.66,8.78,27,3.29a7.27,7.27,0,0,0,3.51-6.14c0-3.08-2.63-5.27-4.83-6.15-2-1.1-5.49-1.1-9.44-1.1-5.27,0-11.2-.22-17.35-2.41-11.42-3.74-20.64-15.6-21.52-27-1.1-13.84,4.83-22.4,9.88-27.23,11-10.1,27-11.64,38.65-9.66,8.78,1.53,15.37,4.61,20.86,9.66a13.13,13.13,0,0,1,.88,18.44,13.42,13.42,0,0,1-11.2,4.18,13.64,13.64,0,0,1-5.71-2c-3.07-2.2-5.27-3.73-9.22-4.39-6.59-1.1-18.89-.66-18,8.78.22,1.1,2.2,4,3.95,4.61,2.2.66,5.5.66,9,.66,5.71.22,13,.22,20.2,3.51,12.08,5.49,20,17.35,20,30.09a34,34,0,0,1-15.15,28.1C808.87,254.47,800.75,256.23,792.41,256.23Z" transform="translate(-10.4 -10.6)"/>
      <path fill="white" className="cls-1" d="M379.72,202.72V167h10a13.18,13.18,0,0,0,0-26.35h-10V126.14a13.18,13.18,0,0,0-26.35,0v14.49h-9.44a13.18,13.18,0,1,0,0,26.35h9.44v29h-.22v29.26A32.34,32.34,0,0,0,360.62,245c5.05,5.71,12.08,8.79,19.32,9.88a49.55,49.55,0,0,0,5.71.22A13.07,13.07,0,0,0,398.82,242a13.21,13.21,0,0,0-13.17-13.18c-1.54,0-3.51.22-4.61-.88-1.54-1.31-1.1-4-1.54-5.7V202.72Z" transform="translate(-10.4 -10.6)"/>
      <path fill="#96c93f" className="cls-4" d="M595.91,128.74a11,11,0,0,1-8.62-4.18c-.66-.83-67.75-84.06-175.34-91.35-55.35-3.74-107.19,8.7-154.09,37a11,11,0,0,1-11.34-18.8c50.83-30.67,107-44.16,166.92-40.1C531,19.27,601.58,107.22,604.53,111a11,11,0,0,1-8.62,17.78Z" transform="translate(-10.4 -10.6)"/>
      <path fill="#96c93f" className="cls-4" d="M213.2,101.25a11,11,0,0,1-7.38-19.11c2.56-2.33,5.22-4.65,7.9-6.91q1.84-1.55,3.78-3.12a11,11,0,0,1,13.84,17q-1.78,1.45-3.48,2.88c-2.47,2.08-4.92,4.22-7.28,6.36A10.93,10.93,0,0,1,213.2,101.25Z" transform="translate(-10.4 -10.6)"/>
      <path fill="#96c93f" className="cls-4" d="M190.56,124.89a10.72,10.72,0,0,1-9.11-4.88,10,10,0,0,1-1-1.9,11.65,11.65,0,0,1-.63-2.06,10.95,10.95,0,0,1,12.9-12.9,10.39,10.39,0,0,1,2.06.61,11,11,0,0,1,1.89,1,10.15,10.15,0,0,1,1.68,1.37,11,11,0,0,1,3.21,7.77,9.66,9.66,0,0,1-.22,2.14,10.8,10.8,0,0,1-.61,2.06,12.82,12.82,0,0,1-1,1.9,13.83,13.83,0,0,1-1.37,1.67,10,10,0,0,1-1.68,1.34,9.3,9.3,0,0,1-1.89,1,11.16,11.16,0,0,1-2.06.63A10.94,10.94,0,0,1,190.56,124.89Z" transform="translate(-10.4 -10.6)"/></svg>

    </div>
  );
};

export default Logo;
