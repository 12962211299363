import React from "react";
import { DateTime } from "luxon";

export const Servicing = ({window, contact, appointment}) => {
  return (
    <div className="bg-blue-400  grid grid-cols-4 rounded-xl text-white text-4xl">
      <div className="flex items-center text-center justify-center border-r-2 border-blue-500">
        <div className="text-center">{window}</div>
      </div>
      <div className="flex items-center text-center justify-center border-r-2 border-blue-500 col-span-2">
        <div className="text-center">{contact ? `${contact.name.first} ${contact.name.last[0]}.` : "‏"}</div>
      </div>
      <div className="flex items-center text-center justify-center">
        <div className="text-center">{appointment ? DateTime.fromISO(appointment.date_time).setZone("UTC").toLocaleString(DateTime.TIME_SIMPLE) : "‏"}</div>
      </div>
    </div>
  );
};

export default Servicing;
