import React from "react";
import { DateTime } from "luxon";

export const UpNext = ({contact, appointment}) => {
  return (
    <div className="bg-blue-600 grid grid-cols-5 rounded-xl text-white text-4xl">
      <div className="flex items-center border-r-2 border-blue-500 col-span-3 ml-5">
        <div>{contact ? `${contact.name.first} ${contact.name.last[0]}.` : "‏"}</div>
      </div>
      <div className="flex items-center text-center justify-center col-span-2">
        <div className="text-center">{appointment ? DateTime.fromISO(appointment.date_time).setZone("UTC").toLocaleString(DateTime.TIME_SIMPLE) : "‏"}</div>
      </div>
    </div>
  );
};

export default UpNext;
