import React from 'react';

import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';

import PackageJSON from '../../../package.json';
import {
  retrieveAppointmentsRequest,
} from '../../action/Appointments/Appointments';
// Additional Dependencies
import { retrieveLocationsRequest } from '../../action/Locations/Locations';
import Logo from '../../components/Logo/Logo';
import Servicing from '../../components/Panels/Servicing';
import ServicingAlert from '../../components/Panels/ServicingAlert';
import UpNext from '../../components/Panels/UpNext';

// Optimized Function to Get URI Path Parameter
function getURIPathParam(id, type) {
  const err = console.error;
  const uuidv4Regex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  const segment = (window.location.pathname.split("/")[id] || "").split("#")[0];
  return typeof id !== "number" || id < 1 || (type && typeof type !== "string")
    ? (err("Invalid Parameter"), null)
    : !segment
    ? (err("Path Segment Does Not Exist"), null)
    : type === "uuidv4" && !uuidv4Regex.test(segment)
    ? (err("Invalid UUIDv4 Format"), null)
    : segment;
}

// Function to Get Application Title
function getAppTitle() {
  const appVersion = parseFloat(PackageJSON.version).toFixed(1);
  const envName = process.env.NODE_ENV[0].toUpperCase() + process.env.NODE_ENV.slice(1);
  const locationName = getURIPathParam(1, "uuidv4");
  return `${locationName} - Version ${appVersion} (${envName})`;
}

// Function to Get App Description
function getAppDescription() {
  return PackageJSON.description;
}

class Monitor extends React.Component {
  timer;

  setTimer = () => {
    this.timer = setInterval(this.props.FetchAppointments, 1000);
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidMount() {
    this.props.FetchLocations();
    this.props.FetchAppointments();
    this.setTimer();
  }

  render() {
    // const { data } = this.props;
    const data = this.props.locations.map((data) => {
      const active = this.props.appointments.find(
        (item) => item.location.uuid === data.location.uuid
      );
      if (active) {
        return active;
      } else {
        return data;
      }
    });

    if (data.length < 8) {
      const limit = 8 - data.length;
      for (let i = 0; i < limit; i++) {
        data.push({
          location: {
            name: "‏",
          },
        });
      }
    }

    const queued = this.props.appointments
      .filter((item) => item.appointment.state.alias === "queued")
      .filter((item) => item.location.uuid === getURIPathParam(1, "uuidv4"));

    if (queued.length < 10) {
      const limit = 10 - queued.length;
      for (let i = 0; i < limit; i++) {
        queued.push({});
      }
    }

    return (
      <div>
        <Helmet>
          <title>{getAppTitle()}</title>
          <meta name="description" content={getAppDescription()} />
        </Helmet>
        {/* Your component's content goes here */}
        <div className="h-screen p-12">
          {/* Columns Container */}
          <div className="flex flex-row h-full">
            {/* Left Column - Servicing */}
            <div className="flex flex-col w-2/3 mr-3">
              {/* Logo */}
              <div className="w-full h-24">
                <Logo />
              </div>
              {/* Column Header */}
              <div className="h-16 text-4xl font-medium text-white">
                <i className="mr-2 fas fa-check-circle"></i>Now Serving
              </div>
              {/* Now Servicing Panels */}
              <div className="grid flex-auto grid-cols-1 gap-2">
                {/* Loop through data and display panels where the window is not 'None' */}
                {data.map((data, index) => {
                  if (data?.appointment?.state.alias === "assigned") {
                    return (
                      <ServicingAlert
                        key={`${index}-active`}
                        window={data?.location.name}
                        contact={data.contact}
                        appointment={data.appointment}
                      />
                    );
                  } else {
                    return (
                      <Servicing
                        key={`${index}-active`}
                        window={data?.location.name}
                        contact={data.contact}
                        appointment={data.appointment}
                      />
                    );
                  }
                })}

                {/* <div></div> */}
                {/* !!! If there are less than eight panels, create a div for each missing panel */}
              </div>
            </div>

            {/* Right Column - Up Next */}
            <div className="flex flex-col w-1/3 ml-3">
              <div className="h-24"></div>
              <div className="h-16 text-4xl font-medium text-white">
                <i className="mr-2 fas fa-clock"></i>Up Next
              </div>
              {/* Up Next List */}
              <div className="flex grid flex-auto grid-cols-1 gap-2">
                {/* Loop through data and display panels where the window is 'None' */}
                {queued.map((data, index) => (
                  <UpNext
                    key={`${index}-queued`}
                    contact={data?.contact}
                    appointment={data?.appointment}
                  />
                ))}

                {/* !!! If there are less than twelve panels, create a div for each missing panel */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locations: state.locationsReducer.data.data,
    appointments: state.appointmentsReducer.data.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    FetchLocations: () => dispatch(retrieveLocationsRequest()),
    FetchAppointments: () => dispatch(retrieveAppointmentsRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Monitor);
