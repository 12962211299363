import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { CSSTransition } from 'react-transition-group';
import useSound from 'use-sound';
import readySound from '../../assets/sound/ready.mp3';
import './transition.css';

export const ServicingAlert = ({window, contact, appointment, timeout}) => {

  const [inProp, setInProp] = useState(false);
  const [play] = useSound(readySound, { interrupt: true });

  useEffect(() => {
    setInProp(true);
  }, []);

  // Play Ready Sound
  useEffect(() => play(), [play]);

  return (
    <CSSTransition in={inProp} timeout={8000} classNames="my-node" onClick={play}>
    <div className="my-node-enter grid grid-cols-4 rounded-xl text-4xl">
    <div className="flex items-center text-center justify-center border-r-2 border-blue-500">
        <div className="text-center">{window}</div>
      </div>
      <div className="flex items-center text-center justify-center border-r-2 border-blue-500 col-span-2">
        <div className="text-center">{contact ? `${contact.name.first} ${contact.name.last[0]}.` : "-"}</div>
      </div>
      <div className="flex items-center text-center justify-center">
        <div className="text-center">{appointment ? DateTime.fromISO(appointment.date_time).setZone("UTC").toLocaleString(DateTime.TIME_SIMPLE) : "-"}</div>
      </div>
    </div>
    </CSSTransition>
  );
};

export default ServicingAlert;
