// Package Dependencies
import { call, put, takeLatest } from 'redux-saga/effects'
// Additional Dependencies
import axios from '../../utility/Axios'
import { DateTime } from "luxon"
// Action Dependencies
import {
  RETRIEVE_APPOINTMENTS_REQUEST,
  retrieveAppointmentsResponse,
  retrieveAppointmentsError
} from '../../action/Appointments/Appointments'

// Function Handle Appointments
function* handleAppointments(data) {
  try {
    // Define Query Parameters
    const transition_state = 'true'
    const date = DateTime.now().setZone("UTC").toFormat('yyyy-MM-dd').toLocaleString(DateTime.DATE_SHORT)
    // Retrieve Appointments Request
    const response = yield call(axios.get_request, `/appointment?transition_state=${transition_state}&date=${date}`)
    // Retrieve Appointments Response
    yield put(retrieveAppointmentsResponse(response.data))
  } catch (error) {
    // Retrieve Appointments Error
    yield put(retrieveAppointmentsError(error))
  }
}

// Export
export const AppointmentsSaga = [ takeLatest(RETRIEVE_APPOINTMENTS_REQUEST, handleAppointments) ]