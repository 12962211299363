// Package Dependencies
import { all } from 'redux-saga/effects'
import { LocationsSaga } from './Locations/Locations'
import { AppointmentsSaga } from './Appointments/Appointments'
// Export
export default function* RootSaga() {
  yield all([
    ...LocationsSaga,
    ...AppointmentsSaga
  ])
}
