// Package Dependencies
import { combineReducers } from 'redux'
// Additional Dependencies
import LocationsReducer from './Locations/Locations'
import AppointmentsReducer from './Appointments/Appointments'
// Combine Reducers
const RootReducer = combineReducers({
  locationsReducer: LocationsReducer,
  appointmentsReducer: AppointmentsReducer
})
// Export
export default RootReducer
