// Package Dependencies
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

// Action Dependencies
import {
  RETRIEVE_LOCATIONS_REQUEST,
  retrieveLocationsError,
  retrieveLocationsResponse,
} from '../../action/Locations/Locations';
// Additional Dependencies
import axios from '../../utility/Axios';

// Optimized Function to Get URI Path Parameter
function getURIPathParam(id, type) {
  const err = console.error;
  const uuidv4Regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  const segment = (window.location.pathname.split("/")[id] || "").split("#")[0];
  return (typeof id !== "number" || id < 1 || (type && typeof type !== "string")) ? (err("Invalid Parameter"), null)
    : !segment ? (err("Path Segment Does Not Exist"), null)
    : (type === "uuidv4" && !uuidv4Regex.test(segment)) ? (err("Invalid UUIDv4 Format"), null)
    : segment;
};

// Function Handle Locations
function* handleLocations(action) {
  try {
    // Define Query Parameters
    const type = "window";
    const state = "active";
    const location_uuid = getURIPathParam(1, "uuidv4");
    // Retrieve Locations Request
    const response = yield call(
      axios.get_request,
      `/location?type=${type}&state=${state}&location_uuid=${location_uuid}`
    );
    // Retrieve Locations Response
    yield put(retrieveLocationsResponse(response.data));
  } catch (error) {
    // Retrieve Locations Error
    yield put(retrieveLocationsError(error));
  }
}

// Export
export const LocationsSaga = [takeLatest(RETRIEVE_LOCATIONS_REQUEST, handleLocations)];
