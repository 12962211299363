import React from 'react';

import {
  HashRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import Monitor from './views/Monitor';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" name="Monitor" component={Monitor} />
      </Switch>
    </Router>
  );
}

export default App;
