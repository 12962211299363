// Package Dependencies
// Additional Dependencies
import {
  RETRIEVE_APPOINTMENTS_REQUEST,
  RETRIEVE_APPOINTMENTS_RESPONSE,
  RETRIEVE_APPOINTMENTS_ERROR
} from '../../action/Appointments/Appointments'

// Define Initial State
const INITIAL_STATE = {
  busy: false,
  processing: false,
  response: false,
  error: false,
  data: {
    data: []
  }
}

// Appointments Reducer
function AppointmentsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Retrieve Appointments Request
    case RETRIEVE_APPOINTMENTS_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // Retrieve Appointments Response
    case RETRIEVE_APPOINTMENTS_RESPONSE:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        data: action.data
      }
    // Retrieve Appointments Error
    case RETRIEVE_APPOINTMENTS_ERROR:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: 'Retrieve Appointments Error'
      }
    default:
      return state
  }
}

// Export
export default AppointmentsReducer